import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Heading, HStack, Icon, Link, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { MdCreditCard } from "react-icons/md";
import appState from "../../utils/app_state";
import { apiRequest, toRecurringCurrencyStr } from "../../utils/helpers";
import Card from "../card";

const AccountBillingPanel = observer(({variant})=>{
  let resolve_alert = null;
  let subscription_card = null;

  useEffect(()=>{
    if (appState.isLoggedIn) {
      appState.loadCurrentSubscription();
    }
  }, []);

  const resolveBilling = async ()=> {
    await appState.resolveBillingState();
  }

  const manageBilling = async ()=>{
    const res = await apiRequest({
      url: "/api/user/create_billing_session",
      method: "POST",
      data: {id: appState.currentUser.id}
    })
    if (res.success) {
      window.location.href = res.data.session_url;
    } else {
      appState.toastResponseError(res);
    }
  }


  if (appState.currentUser.is_billable && !appState.currentUser.billing_resolved) {
    resolve_alert = (
      <Alert status="warning" mb={4}>
        <AlertIcon/>
        <Box>
          <AlertTitle>
            Your account has billable items that need to be resolved. 
          </AlertTitle>
          <AlertDescription>
            This will prevent your contact cards from showing up in search results.

          </AlertDescription>
        </Box>
        <Box>
          <Button variant="solid" colorScheme="green" onClick={resolveBilling} isLoading={appState.billingResolving}>
            Resolve
          </Button>
        </Box>
      </Alert>
    )
  }

  if (appState.currentUser.stripe_subscription) {
    const sub = appState.currentUser.stripe_subscription;
    const disc_item = sub.items.data.find((item)=>item.price.product.metadata.key == 'discoverable');
    const boost_item = sub.items.data.find((item)=>item.price.product.metadata.key == 'promoted_boost');
    subscription_card = (
      <Card>
        <HStack spacing='4' mb={4}>
          <Icon as={MdCreditCard} w={8} h={8} color="green" verticalAlign='middle' />
          <Heading size='md' mb="2">Current Subscription</Heading>
        </HStack>
        {disc_item && <Box mb={2}>
          <Text fontSize="lg">You have <Text as="strong" color="green">{disc_item.quantity} contact cards</Text> that are discoverable on the search page</Text>
          <Text color="gray.500">Billed at {toRecurringCurrencyStr(disc_item.price)} per card</Text>
        </Box>}
        {boost_item && <Box mb={2}>
          <Text fontSize="lg">You have <Text as="strong" color="green">{boost_item.quantity} promoted boosts</Text> across all discoverable cards to make your cards rank higher</Text>
          <Text color="gray.500">Billed at {toRecurringCurrencyStr(boost_item.price)} per boost</Text>
        </Box>}
        <Link display="inline" fontWeight="bold" onClick={manageBilling}>more details</Link>
      </Card>
    )
  }

  if (variant =='alerts') {
    return resolve_alert;
  }
  return (
    <>
    {resolve_alert}
    {subscription_card || (
      <Text>No subscription found</Text>
    )}
    </>
  );
});

export default AccountBillingPanel;