import { types } from "mobx-state-tree";
import { APIModel } from "./modelable";

const UserState = types.compose("UserState", APIModel, types
  .model({
    subject_type: types.string,
    subject_id: types.string,
    user_id: types.string,
    bookmarked_at: types.maybeNull(types.string),
    viewed_at: types.maybeNull(types.string)
  }));

export default UserState;