import { Avatar, Box, Button, Center, Container, Flex, Heading, HStack, Icon, SimpleGrid, SkeletonCircle, SkeletonText, Text } from "@chakra-ui/react";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { MdAdd, MdContacts, MdLocationOn } from "react-icons/md";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Card from "../components/card";
import ContactCard from "../components/contact/contact_card";
import LoadMore from "../components/load_more";
import SearchBox from "../components/search_box";
import Contact from "../models/contact";
import appState from "../utils/app_state";
import { apiRequest } from "../utils/helpers";

const SearchPage = observer(()=>{
  const [tags, setTags] = useState([]);
  const [locationID, setLocationID] = useState();
  const [location, setLocation] = useState();
  const [contacts, setContacts] = useState(null);
  const [pagination, setPagination] = useState();
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(action(()=>{
    let newTags = [];
    let newLocationID = undefined;
    let newIndex = undefined;
    const url_tags = searchParams.get('tags');
    if (url_tags) { newTags = decodeURI(url_tags).split(","); }
    const url_loc = searchParams.get('loc');
    if (url_loc) { newLocationID = decodeURI(url_loc); }
    const url_index = searchParams.get('index');
    if (url_index) { newIndex = decodeURI(url_index); }

    setTags(newTags);
    setLocationID(newLocationID);
    setIndex(newIndex);
  }), [searchParams]);

  useEffect(()=> {
    loadLocation(locationID);
  }, [locationID])

  useEffect(()=> {
    if (index) {
      console.log("Loading results");
      loadSearchResults();
    }
  }, [index, tags, locationID]);

  const pageTitle = useMemo(()=>{
    let ret = "All Results";
    if (index == "recentlyViewed") {
      ret = "Recently Viewed Cards"
    } else if (index == "bookmarked") {
      ret = "Bookmarked Cards"
    }
    return ret;
  });

  const loadLocation = action(async (loc_id)=>{
    if (!loc_id) { return }
    const res = await apiRequest({
      url: "/api/locations",
      method: 'GET',
      data: { id: loc_id }
    });
    if (res.success) {
      setLocation(res.data);
    }
  });

  const loadSearchResults = action(async ({more=false}={})=>{
    // determine page
    let np = 1;
    if (more) {
      np = pagination.page + 1;
    }
    // determine url and filters from index
    let url = "/api/contacts";
    let data = {};
    if (index == 'search') {
      url = "/api/contacts/search";
      data = {
        tags: tags,
        location: locationID,
        limit: 50,
        sort: 'promoted',
        page: np
      };
    } else if (index == 'recentlyViewed') {
      const contact_ids = appState.getRecentlyViewedContactIds();
      data = {
        filters: {
          with_ids: contact_ids
        }
      };
    } else if (index == 'bookmarked' && appState.isLoggedIn) {
      data = {
        filters: {
          bookmarked_by_user_id: appState.currentUser.id
        },
        limit: 50,
        page: np
      };
    } else {
      return;
    }
    const res = await apiRequest({
      url: url,
      method: 'GET',
      loading: setLoading,
      data: data
    });
    if (res.success) {
      let cs = res.data.map((cd)=> Contact.create(cd) );
      if (more) {
        setContacts( contacts.concat(cs) );
      } else {
        setContacts(cs);
      }
      setPagination(res.meta);
    }
  });

  const loadMore = action(()=>{
    loadSearchResults({more: true})
  })

  const createReference = ()=>{
    navigate("/create?reference=true")
  }

  let cards = [];
  if (contacts != null) {
    cards = contacts.map((c)=>
      <ContactCard key={c.id} contact={c}/>
    )
  }
  if (loading && cards.length == 0) {
    cards = [1,2,3,4].map((v)=>
      <Box key={v} padding="4">
        <SkeletonCircle size={10}/>
        <SkeletonText mt="4"/>
      </Box>
    )
  }

  return (
    <Container>
      <SearchBox initialTags={tags} initialLocation={location}></SearchBox>

      {contacts && (
        <>
        <Flex alignItems="center" mb={4}>
          <Heading size="md" flex="1">{pageTitle}</Heading>
          {appState.isLoggedIn && <Button variant="outline" colorScheme="black" size="sm" leftIcon={<MdAdd/>} onClick={createReference}>Add</Button>}
        </Flex>
        <SimpleGrid columns={2} spacing={4}>
          {cards}
        </SimpleGrid>
        {pagination?.pages_count == 0 && <Text color="gray.500">No contacts found.</Text>}
        <LoadMore pagination={pagination} load={loadMore}/>
        </>
      )}
    </Container>
  );
});

export default SearchPage;