import { useToast } from "@chakra-ui/react";

export async function apiRequest({url, method, loading, data}) {
  /*
  if (process.env.NODE_ENV != 'production') {
    url = "http://home.remote:3051" + url;
  }
  */
  let fopts = {
    method: method,
    headers: {
      'Accept': 'application/json'
    }
  };
  if (data) {
    if (method == 'GET') {
      url = url + "?" + convertToSearchParams(data);
    }
    else if (data instanceof FormData) {
      fopts.body = data;
    } else {
      fopts.headers['Content-Type'] = 'application/json'
      fopts.body = JSON.stringify(data);
    }
  }
  if (loading) { loading(true) };
  const resp = await fetch(url, fopts);
  const json = await resp.json();
  if (loading) { loading(false) };
  return json;
}

export function mixin(self, mixins, args) {
  for (var m of mixins) {
    // process methods
    for (var [fk, fn] of Object.entries(m.methods)) {
      self[fk] = fn;
    }
    // construct
    if (m.init) {
      m.init.apply(self, args);
    }
  }
}

export function convertToSearchParams(obj) {
  const pobj = {};
  for (const [k, v] of Object.entries(obj)) {
    if (typeof(v) == 'object') {
      pobj[k] = JSON.stringify(v);
    } else {
      pobj[k] = v;
    }
  }
  return new URLSearchParams(pobj).toString();
}

export function toCurrencyStr(val) {
  if (val == null) return "$ ---";
  const dollars = val / 100;
  const str = dollars.toLocaleString("en-US", {style:"currency", currency:"USD"});
  return str
}

export function toRecurringCurrencyStr(price) {
  const amt = toCurrencyStr(price.unit_amount);
  const interval = price.recurring.interval;
  return `${amt} per ${interval}`;
}

export function pluralize(val, singleText, pluralText) {
  if (val == null) val = 0;
  if (val == 1) {
    return `${val} ${singleText}`;
  } else {
    return `${val} ${pluralText}`;
  }
}

export function toFullURL(path) {
  return `https://justqrme.com${path}`;
}