import { Alert, AlertDescription, AlertIcon, Box, Button, Heading, HStack, Icon, Input, InputGroup, InputRightAddon, InputRightElement, Link, useToast } from "@chakra-ui/react";
import { AsyncSelect, CreatableSelect, Select } from "chakra-react-select";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import { MdPublic } from "react-icons/md";
import appState from "../../utils/app_state";
import { apiRequest } from "../../utils/helpers";
import Card from "../card";
import ModelFormField from '../model_form_field'

const ContactDiscoveryForm = observer(({contact})=>{
  const toast = useToast();

  const discoveryTagOptions = ()=>{
    return contact.discovery_tags.map((t)=>({ value: t, label: t }));
  }

  const loadLocationOptions = async (inputValue)=>{
    const res = await apiRequest({
      url: "/api/locations/search",
      method: 'GET',
      data: {
        query: inputValue,
        where: {country: "United States"},
        match: 'word_start',
        limit: 10,
        fields: ['city', 'admin']
      }
    });
    return res.data;
  }

  const handleDiscoveryTagsChanged = action((newValue)=>{
    contact.set('discovery_tags', newValue.map((opt)=>opt.value));
    window.contact = contact;
  });

  const handleDiscoveryLocationsChanged = action((newValue)=>{
    contact.set('discovery_location_ids', newValue.map((l)=>l.id));
    contact.set('discovery_locations', newValue);
  });

  const saveSettings = async ()=>{
    //console.log(contact);
    const res = await contact.save({withFields: ['id', 'discovery_tags', 'discovery_location_ids', 'short_key']});
    if (res.success) {
      toast({
        title: "Contact discovery settings saved.",
        status: "success"
      });
    }
  };

  return (
    <Card minW="full">
      <HStack spacing='4' mb={4}>
        <Icon as={MdPublic} w={8} h={8} color="green" verticalAlign='middle' />
        <Heading size='md' mb="2">
          Manage Discovery for {contact.full_name}
        </Heading>
      </HStack>

      {contact.discovery_state != 'discoverable' && (
        <Alert status="warning" mb={2}>
          <AlertIcon/>
          <AlertDescription>
            You must enabled discovery above in order for this contact to be found in search results. 
          </AlertDescription>
        </Alert>
      )}

      <Heading size='sm' mb={2}>Discovery Tags</Heading>
      <Box mb={8}>
          <CreatableSelect
            isMulti
            placeholder="realtor, hair stylist, trainer, ..."
            value={discoveryTagOptions()}
            onChange={handleDiscoveryTagsChanged}
            components={{DropdownIndicator: ()=>null, IndicatorSeparator: ()=>null}}
            />
      </Box>

      <Heading size='sm' mb={2}>Discovery Locations</Heading>
      <Box mb={8}>
          <AsyncSelect
            isMulti
            placeholder="Type to add cities"
            getOptionLabel={(opt)=>{return opt.display_name}}
            getOptionValue={(opt)=>{return opt.id}}
            value={contact.discovery_locations}
            loadOptions={loadLocationOptions}
            components={{DropdownIndicator: ()=>null, IndicatorSeparator: ()=>null}}
            onChange={handleDiscoveryLocationsChanged}
            menuPlacement='top'
            />
      </Box>

      <Heading size='sm' mb={2}>Short URL</Heading>
      <Box mb={8}>
        <ModelFormField type='text' model={contact} field="short_key" leftAddon="justqrme.com/@"/>
      </Box>

      <Button colorScheme='green' w="full" onClick={saveSettings}>Save Settings</Button>
    </Card>
  );
});

export default ContactDiscoveryForm;