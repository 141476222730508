import { makeAutoObservable } from 'mobx'
import { types } from 'mobx-state-tree';
import { IoQrCodeOutline, IoLogoLinkedin, IoLogoTwitter, IoLogoInstagram, IoPhonePortrait, IoHome, IoBusiness, IoMail, IoGlobe, IoLogoTiktok, IoLogoSnapchat, IoLogoYoutube, IoLogoFacebook, IoLogoVenmo } from 'react-icons/io5'
import appState from '../utils/app_state';
import { mixin } from '../utils/helpers';
import { APIModel, ensureArrayType } from './modelable';
import UserState from './user_state';

const Contact = types.compose("Contact", APIModel, types
  .model({
    first_name: types.maybeNull(types.string),
    last_name: types.maybeNull(types.string),
    company: types.maybeNull(types.string),
    company_title: types.maybeNull(types.string),
    mobile_phone: types.maybeNull(types.string),
    home_phone: types.maybeNull(types.string),
    work_phone: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    street_1: types.maybeNull(types.string),
    street_2: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    postal_code: types.maybeNull(types.string),
    website: types.maybeNull(types.string),
    twitter: types.maybeNull(types.string),
    instagram: types.maybeNull(types.string),
    linkedin: types.maybeNull(types.string),
    tiktok: types.maybeNull(types.string),
    snapchat: types.maybeNull(types.string),
    youtube: types.maybeNull(types.string),
    facebook: types.maybeNull(types.string),
    venmo: types.maybeNull(types.string),
    bio: types.maybeNull(types.string),

    short_key: types.maybeNull(types.string),
    discovery_tags: ensureArrayType(types.string),
    discovery_locations: ensureArrayType(types.frozen()),
    discovery_location_ids: ensureArrayType(types.string),
    discovery_state: types.maybeNull(types.string),
    discovery_boost_level: types.maybeNull(types.integer),

    vcard: types.maybeNull(types.string),
    qr_code: types.maybeNull(types.string),
    avatar: types.maybeNull(types.frozen()),

    user_id: types.maybeNull(types.string),
    user_state: types.maybeNull(UserState),
    is_reference: types.maybeNull(types.boolean)

  })
  .views((self)=>({
    get full_name() {
      return `${self.first_name} ${self.last_name}`;
    },

    get name_and_company() {
      return (this.has_company ? `${this.full_name} @ ${this.company}` : this.full_name);
    },

    get has_address() {
      return self.street_1 || self.city || self.state || self.postal_code;
    },

    get has_city_state() {
      return self.city && self.state;
    },

    get has_company() {
      return !!self.company;
    },

    get has_name() {
      return self.first_name && self.last_name;
    },

    get has_phone() {
      return self.mobile_phone || self.home_phone || self.work_phone;
    },

    get has_email() {
      return !!self.email;
    },

    get has_social() {
      return self.socials_given.length > 0;
    },

    get is_valid() {
      return (self.first_name && (self.has_phone || self.email || self.has_social));
    },

    get socials_given() {
      return Contact.SOCIAL_CONTACT_ATTRS.filter((attr)=>{return !!self[attr.key]}).map((attr)=>attr.key);
    },

    get phones_given() {
      return Contact.PHONE_CONTACT_ATTRS.filter((attr)=>{return !!self[attr.key]}).map((attr)=>attr.key);
    },

    get static_map_url() {
      const addr_str = `${self.street_1} ${self.street_2} ${self.city} ${self.state} ${self.postal_code}`;
      return `/api/map_image?address=${addr_str}`;
    },

    get google_map_url() {
      const addr_str = `${self.street_1 || ""} ${self.street_2 || ""} ${self.city} ${self.state} ${self.postal_code || ""}`;
      const token = "AIzaSyD2xczfRM_9wykBQhUTL3E4x4CgfHxctQY";
      return `https://www.google.com/maps/embed/v1/place?key=${token}&q=${encodeURI(addr_str)}`
    },

    get avatar_url() {
      return self.avatar?.files.default?.storage_url;
    },

    get view_path() {
      return `/contact/${self.id}`
    },

    get edit_path() {
      return `/contact/${self.id}/edit`
    },

    get discovery_path() {
      return `/contact/${self.id}/discovery`
    },

    get qr_path() {
      return `/qr/${self.id}`
    },

    get full_company_title() {
      if (self.company_title && self.company) {
        return `${self.company_title} at ${self.company}`;
      } else if (self.company_title) { return self.company_title; }
      else if (self.company) { return self.company; }
      else { return undefined; }
    },

    get discovery_configured() {
      return self.discovery_tags.length > 0 && self.discovery_location_ids.length > 0;
    },

    get user_bookmarked() {
      return self.user_state?.bookmarked_at != null;
    }
  }))
  .actions((self)=>({
    async loadBy({id, shortKey, include}) {
      const data = {};
      if (id) {
        data.id = id;
      } else if (shortKey) {
        data.filters = {
          with_short_key: shortKey
        };
        data.first = true;
      }
      const res = await self.load({data: data, include: include})
      return res;
    }
  }))
);

Contact.objectType = 'contact';
Contact.apiRoutes = {
  index: {method: 'GET', url: '/api/contacts'},
  update: {method: 'POST', url: '/api/contact'}
};

Contact.CONTACT_ATTRS = [
  {name: 'First name', key: 'first_name', type: 'primary'},
  {name: 'Last name', key: 'last_name', type: 'primary'},
  {name: 'Company', key: 'company', type: 'primary'},
  {name: 'Company Title', key: 'company_title', type: 'primary'},
  {name: 'Bio', key: 'bio', type: 'primary'},
  {name: 'Website', key: 'website', icon: IoGlobe, type: 'primary'},
  {name: 'E-mail', key: 'email', icon: IoMail, type: 'primary'},
  // address
  {name: 'Street 1', key: 'street_1', type: 'address'},
  {name: 'Street 2', key: 'street_2', type: 'address'},
  {name: 'City', key: 'city', type: 'address'},
  {name: 'State', key: 'state', type: 'address'},
  {name: 'Zip', key: 'postal_code', type: 'address'},
  // phones
  {name: 'Mobile phone', key: 'mobile_phone', icon: IoPhonePortrait, type: 'phone'},
  {name: 'Home phone', key: 'home_phone', icon: IoHome, type: 'phone'},
  {name: 'Work phone', key: 'work_phone', icon: IoBusiness, type: 'phone'},
  // socials
  {name: 'LinkedIn', key: 'linkedin', icon: IoLogoLinkedin, type: 'social', urlPrefix: ""},
  {name: 'Twitter', key: 'twitter', icon: IoLogoTwitter, type: 'social', urlPrefix: "https://twitter.com/"},
  {name: 'Instagram', key: 'instagram', icon: IoLogoInstagram, type: 'social', urlPrefix: "https://instagram.com/"},
  {name: 'TikTok', key: 'tiktok', icon: IoLogoTiktok, type: 'social', urlPrefix: "https://tiktok.com/@"},
  {name: 'Youtube', key: 'youtube', icon: IoLogoYoutube, type: 'social', urlPrefix: "https://youtube.com/c/"},
  {name: 'SnapChat', key: 'snapchat', icon: IoLogoSnapchat, type: 'social', urlPrefix: "https://snapchat.com/add/"},
  {name: 'Facebook', key: 'facebook', icon: IoLogoFacebook, type: 'social', urlPrefix: ""},
  {name: 'Venmo', key: 'venmo', icon: IoLogoVenmo, type: 'social', urlPrefix: "https://venmo.com/@", urlSuffix: "?txn=pay"}
];
Contact.CONTACT_ATTRS.findForKey = (k)=>{
  return Contact.CONTACT_ATTRS.find((v)=>v.key == k);
}
Contact.CONTACT_ATTRS.filterByTypes = (types)=> {
  return Contact.CONTACT_ATTRS.filter((v)=> types.include(v.type));
}

Contact.SOCIAL_CONTACT_ATTRS = Contact.CONTACT_ATTRS.filter((v)=>v.type=='social');
Contact.PHONE_CONTACT_ATTRS = Contact.CONTACT_ATTRS.filter((v)=>v.type=='phone');

export default Contact;