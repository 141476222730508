import { Button, Center } from "@chakra-ui/react";

const LoadMore = ({pagination, load, loading})=>{

  if (!pagination || pagination.pages_count == 0 || pagination.pages_count == pagination.page) {
    return null;
  }

  return (
    <Center>
      <Button onClick={load} isLoading={loading}>Load More</Button>
    </Center>

  );

}

export default LoadMore