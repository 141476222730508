import Contact from '../models/contact.js'
import { useEffect, useState } from 'react'
import Card from '../components/card'
import { Avatar, Button, Container, Box, Heading, HStack, VStack, ModalCloseButton, ModalBody, Modal, ModalOverlay, ModalContent, ModalHeader, Text, Divider, Icon, List, ListItem, ListIcon, Tabs, TabList, TabPanels, TabPanel, Tab, Image, SkeletonCircle, Skeleton, SkeletonText } from '@chakra-ui/react'
import { IoEllipsisVertical, IoShareOutline } from 'react-icons/io5'
import { MdCheckCircle } from 'react-icons/md'
import appState from '../utils/app_state'
import { apiRequest, toFullURL } from '../utils/helpers.js'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

const QRPage = observer(()=>{

  const [contact, setContact] = useState(null);
  const [ isHomeScreenModalOpen, setIsHomeScreenModalOpen ] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  // on mount load contact from id or URL
  useEffect(()=> {
    loadContact();
    return ()=> {
      appState.needsBack(false);
    }
  }, [])

  const loadContact = async ()=>{
    const c = Contact.create();
    const res = await c.loadBy({id: params.contactID, shortKey: params.contactShortKey, include: ['qr_code']})
    if (res.success) {
      setContact(c);
      appState.needsBack(true, {path: c.view_path});
    }
  }

  const viewContact = ()=> {
    navigate(contact.view_path);
    //router.push(`/?contact=${contact_data}`);
  }

  const shareContact = ()=> {
    navigator.share({
      title: contact.name_and_company,
      url: toFullURL(contact.view_path)
    });
  }


  if (!contact) {
    return (
      <Container>
        <VStack spacing="8">
          <SkeletonCircle size="24"/>
          <Skeleton height="200px" width="200px" rounded="xl"/>
          <Skeleton height="12" width="300px"/>
          <Skeleton height="8" width="250px"/>
        </VStack>
      </Container>
    )
  }

  return (
    <div>
      <Container>
        <Card padding="0" overflow="hidden">
          <Box bgImage="url('/images/milad-fakurian-unsplash-web.jpg')" bgPosition="center" bgSize="cover" rounded="xl" padding="4">
            <VStack spacing="4">
              <Avatar bg="blue.400" color="white" name={contact.full_name} src={contact.avatar_url} size='xl' showBorder/>
              <Image src={contact.qr_code} rounded="xl" />
            </VStack>
          </Box>
          <VStack padding="5">
            <Heading fontWeight="bold">{contact.full_name}</Heading>
            <Heading size='md' color="gray.500">{contact.company}</Heading>
          </VStack>
        </Card>
        <Card>
          <VStack>
            <Button colorScheme="green" minW='xs' onClick={()=>setIsHomeScreenModalOpen(true)}>Add To Home Screen</Button>
            <HStack minW='xs' align="stretch">
              <Button onClick={viewContact} flex="1">View Card</Button>
              {navigator.share && <Button onClick={shareContact} flex="1">Share Card</Button>}
            </HStack>
          </VStack>
        </Card>
      </Container>

      <Modal isOpen={isHomeScreenModalOpen} onClose={() => setIsHomeScreenModalOpen(false)} isCentered size="sm">
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Add To Home Screen</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <Tabs variant="enclosed">
              <TabList>
                <Tab>iPhone (Safari)</Tab>
                <Tab>Android (Chrome)</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <List spacing={3}>
                    <ListItem>
                      <ListIcon as={MdCheckCircle} color='green.500' />
                      Click the browser share icon (<Icon as={IoShareOutline} color="blue"/>)
                    </ListItem>
                    <ListItem>
                      <ListIcon as={MdCheckCircle} color='green.500' />
                      Choose <strong>Add to Home Screen</strong>
                    </ListItem>
                  </List>

                </TabPanel>
                <TabPanel>
                  <List spacing={3}>
                    <ListItem>
                      <ListIcon as={MdCheckCircle} color='green.500' />
                      Click the browser options icon (<Icon as={IoEllipsisVertical} color="grey"/>)
                    </ListItem>
                    <ListItem>
                      <ListIcon as={MdCheckCircle} color='green.500' />
                      Choose <strong>Add to Home Screen</strong>
                    </ListItem>
                  </List>

                </TabPanel>

              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  )
});

export default QRPage;

export async function getServerSideProps(context) {
  const contact_data_uri = context.query.contact
  var contact, contact_data;
  if (contact_data_uri) {
    contact_data = JSON.parse(decodeURI(atob(contact_data_uri)));
    contact = new Contact(contact_data);
  }
  //var qrcode_url = await QRCode.toDataURL(contact.toVCard());
  return {
    props: {
      //qrcodeURL: qrcode_url,
      contactData: contact_data
    }
  }
}