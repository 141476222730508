import { makeAutoObservable } from "mobx";
import { types } from "mobx-state-tree";
import { mixin } from "../utils/helpers";
import { APIModel } from "./modelable";

/*
export default class User {
  email = undefined
  passcode = undefined
  billing_plan_key = undefined
  billing_plan_active = undefined

  constructor(obj) {
    makeAutoObservable(this);
    mixin(this, [Modelable], arguments);
  }
}
*/

const User = types.compose("User", APIModel, types
  .model({
    email: types.string,
    passcode: types.maybeNull(types.string),
    billing_plan_key: types.maybeNull(types.string),
    billing_plan_active: types.maybeNull(types.boolean),
    stripe_subscription: types.maybeNull(types.frozen()),
    billing_resolved_at: types.maybeNull(types.string),
    billable_items: types.maybeNull(types.frozen()),
    created_at: types.maybeNull(types.string)
  })
  .views((self)=>({
    get billing_resolved() {
      return self.billing_resolved_at != null;
    },
    get is_billable() {
      if (!self.billable_items) return null;
      const di = self.billable_items.find((i)=>i.product_key =='discoverable');
      return di?.quantity > 0;
    },
    get is_recently_created() {
      if (!self.created_at) return null;
      const diff = (new Date()) - new Date(self.created_at);
      return (diff / (1000 * 60 * 60)) < 1
    }
  }))
);

export default User;