import '@fontsource/abel'
import '@fontsource/outfit/400.css'
import '@fontsource/outfit/700.css'
import { Box, ChakraProvider, extendTheme, Modal, ModalOverlay, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'

import './styles/globals.css'
import HeaderBar from './components/header_bar'
import appState from './utils/app_state'
import LoginView from './components/login_view'
import HomePage from './pages/home_page'
import QRPage from './pages/qr_page'
import ContactPage from './pages/contact_page'
import ContactEditPage from './pages/contact_edit_page'
import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import SearchPage from './pages/search_page'
import AccountPage from './pages/account_page'
import { Helmet } from 'react-helmet-async'
import ContactDiscoveryPage from './pages/contact_discovery_page'
import AdminPage from './pages/admin_page'

const activeFloatingLabelStyles = {
  transform: "scale(0.85) translateY(-12px)"
};

const theme = extendTheme({
  styles: {
    global: {
      body: {
        color: "#2c404a",
        bg: '#f1f3f4'
      }
    }
  },
  fonts: {
    heading: 'Outfit',
    body: 'Outfit'
  },
  components: {
    Card: {
      variants: {
        'basic': {
          padding: 4,
          bg: 'white',
          boxShadow: 'lg',
          rounded: 'xl',
          marginBottom: 4
        }
      },
      defaultProps: {
        variant: 'basic'
      }
    },
    Divider: {
      variants: {
        'basic': {
          borderColor: "gray.400",
          borderStyle: 'solid',
          marginTop: 4,
          marginBottom: 8
        }
      }
    },
    Form: {
      variants: {
        'floating': {
          container: {
            _focusWithin: {
              label: {
                ...activeFloatingLabelStyles
              }
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label": {
              ...activeFloatingLabelStyles
            },
            input: {
              paddingTop: "35px",
              paddingBottom: "15px",
            },
            label: {
              top: 0,
              left: 0,
              position: "absolute",
              color: "gray.400",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: "14px",
              transformOrigin: "left top"
            }
          }
        }
      }
    },
    Heading: {
      baseStyle: {
        fontWeight: 'normal'
      }
    }
  },
});

const App = observer(()=>{
  const [ loginEmail, setLoginEmail ] = useState('');
  const [ loginContact, setLoginContact ] = useState(null);

  const navigate = useNavigate();

  useEffect(async ()=>{
    // load global context
    await appState.loadGlobalContext();
    // load recently viewed for header
    appState.getRecentlyViewedContactIds();
    //console.log(appState.contextLoaded);
    window.appState = appState;

    appState.emitter.on("app.navigate", (path)=> {
      navigate(path);
    });
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Helmet helmetData={appState.helmetData}>
        <title>justQRMe</title>
      </Helmet>
      <HeaderBar/>
      { appState.contextLoaded && (
      <>
        <Routes>
          <Route path="/" element={<HomePage />}/>
          <Route path="/create" element={<ContactEditPage mode='create' />}/>
          <Route path="/qr/:contactID" element={<QRPage />}/>
          <Route path="/qr/@:contactShortKey" element={<QRPage />}/>
          <Route path="/contact/:contactID" element={<ContactPage />}/>
          <Route path="/@:contactShortKey" element={<ContactPage />}/>
          <Route path="/contact/:contactID/edit" element={<ContactEditPage mode='edit' />}/>
          <Route path="/contact/:contactID/discovery" element={<ContactDiscoveryPage />}/>
          <Route path="/search" element={<SearchPage />}/>
          <Route path="/account" element={<AccountPage />}/>
          {appState.hasSiteRole && <Route path="/admin" element={<AdminPage />}/>}
        </Routes>
        <Modal isOpen={appState.loginModalVisible} onClose={() => { appState.loginModalVisible = false; }} isCentered size="sm">
          <ModalOverlay/>
          <LoginView />
        </Modal>
      </>
      ) }
      <Box color="gray.500" fontSize="sm" textAlign="center" my={4}>
        &copy; {(new Date()).getFullYear()} JustQRMe &bull; <a href="mailto:support@justqrme.com">Contact</a>
      </Box>
    </ChakraProvider>
  )
});

export default App


/*
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
*/