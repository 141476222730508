import { Avatar, Heading, HStack, Icon, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { Link, useSearchParams } from "react-router-dom";
import { MdLocationOn } from "react-icons/md";
import Card from "../card";

const ContactCard = observer(({contact})=>{
  const c = contact;
  return (
    <Link to={c.view_path}>
      <Card>
        <Avatar bg="blue.400" color="white" name={c.full_name} src={c.avatar_url} size="lg" showBorder mb={3}></Avatar>
        <Heading size="md" mb={1} fontWeight="bold">{c.full_name}</Heading>
        {c.full_company_title && <Heading size="sm" mb={1}>{c.full_company_title}</Heading>}
        <Text fontSize="sm">{contact.bio}</Text>
        {c.has_city_state && (
        <HStack color="gray.400">
          <Icon as={MdLocationOn} />
          <Text>{c.city}, {c.state}</Text>
        </HStack>
        )}
      </Card>
    </Link>
  );
});

export default ContactCard;