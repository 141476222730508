import { Box, Container, Heading, Stat, StatGroup, StatLabel, StatNumber } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import Card from "../components/card";
import { apiRequest } from "../utils/helpers";

const AdminPage = observer(()=>{

  const [adminContext, setAdminContext] = useState();

  useEffect(()=>{
    loadAdminContext();
  }, []);

  const loadAdminContext = async ()=>{
    const res = await apiRequest({
      url: "/api/admin_context",
      method: "GET",
    })
    if (res.success) {
      setAdminContext(res.data);
    }
  };

  const statGroups = useMemo(()=>{
    let ret = []
    if (!adminContext) return ret;
    const ac = adminContext;
    let user_group = {
      title: "User Stats",
      key: "user",
      stats: [
        {title: "Users", value: ac.users_count},
        {title: "Discoverable Users", value: ac.discoverable_users_count}
      ]
    }
    ret.push(user_group);
    let contact_group = {
      title: "Contact Stats",
      key: "contact",
      stats: [
        {title: "Contacts", value: ac.contacts_count},
        {title: "Discoverable Contacts", value: ac.discoverable_contacts_count}
      ]
    };
    ret.push(contact_group);
    return ret;
  })

  return (
    <Container>
      <Card>
        <Heading size="md" mb={4}>Admin Page</Heading>
        {statGroups.map( (group)=>
        <Box key={group.key}>
          <Heading size="sm" fontWeight="bold" mb={2}>{group.title}</Heading>
          <StatGroup key={group.key} mb={2}>
            {group.stats.map( (stat)=>
              <Stat key={stat.title}>
                <StatLabel>{stat.title}</StatLabel>
                <StatNumber>{stat.value}</StatNumber>
              </Stat>
            )}
          </StatGroup>
        </Box>
        )}
      </Card>
    </Container>
  );
});

export default AdminPage;