import { action } from "mobx";
import { applySnapshot, getSnapshot, getType, types } from "mobx-state-tree"
import { apiRequest } from "../utils/helpers";

export const APIModel = types
  .model("APIModel", {
    id: types.maybeNull(types.string),
    errors: types.array(types.frozen())
  })
  .volatile((self)=>({
    is_saving: false
  }))
  .views((self)=>({
    errorsForField(field) {
      let errs = (self.errors || []).filter((e)=>{return e.field == field});
      return errs;
    },
    get modelType() { return getType(self) },
    get apiRoutes() { return self.modelType.apiRoutes; }
  }))
  .actions((self)=>({
    set(field, val) {
      self[field] = val;
    },
    setSaving(val) {
      self.is_saving = val;
    },
    handleData(data) {
      //applySnapshot(self, data);
      // allow data to merge
      for (const [key, val] of Object.entries(data)) {
        self[key] = val;
      }
    },
    toJS({fields}={}) {
      var ret = {};
      const s = getSnapshot(self);
      if (fields) {
        for (const field of fields) {
          ret[field] = s[field];
        }
      } else {
        ret = s;
      }
      return ret;
    },
    toJSONString() {
      return JSON.stringify(this.toJS());
    },
    async load({id, include, data}) {
      data ||= {};
      if (id) { data.id = id; }
      if (include) { data.include = include; }
      const res = await apiRequest({
        url: self.apiRoutes.index.url,
        method: self.apiRoutes.index.method,
        data: data
      });
      if (res.success) {
        self.handleData(res.data);
      }
      return res;
    },
    async save({withFields, include, data, url}) {
      data ||= {};
      url ||= self.apiRoutes.update.url;
      if (!data.id) {
        data.id = self.id;
      }
      if (withFields) {
        const md = self.toJS({fields: withFields});
        data = {...data, ...md};
      }
      data.include ||= include;
      const res = await apiRequest({
        url: url,
        method: self.apiRoutes.update.method,
        data: data,
        loading: (val)=> {self.set('is_saving', val)}
      });
      if (res.success) {
        self.handleData(res.data);
        // notify event bus
        if (self.modelType.eventBus && self.modelType.objectType) {
          self.modelType.eventBus.emit(`data:${self.modelType.objectType}.updated`, res.data);
        }
      } else if (res.data) {
        self.set('errors', res.data.errors);
      }
      return res;
    },
    subscribeToEvents() {
      if (!self.modelType.eventBus) return;
      self.modelType.eventBus.on(`data:${self.modelType.objectType}.updated`, self.handleData);
    },
    unsubscribeToEvents() {
      if (!self.modelType.eventBus) return;
      self.modelType.eventBus.off(`data:${self.modelType.objectType}.updated`, self.handleData);
    }
  }));

export const ensureArrayType = function (atype) {
  return types.optional(types.array(atype), [], [null, undefined]);
};

export const Modelable = {
  init(obj) {
    if (obj) { this.handleData(obj); }
  },
  methods: {
    handleData: action(function(obj) {
      for (const key in obj) {
        this[key] = obj[key];
      }
    }),
    toJS({fields}={}) {
      var ret = {}
      if (fields) {
        for (const field of fields) {
          ret[field] = this[field];
        }
      } else {
        for (const key in this) {
          ret[key] = this[key];
        }
      }
      return ret
    },
    toJSONString() {
      return JSON.stringify(this.toJS());
    },
    errorsForField(field) {
      let errs = (this.errors || []).filter((e)=>{return e.field == field});
      return errs;
    }
  }
};