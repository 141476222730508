import { Box, Button, Container, Heading, HStack, Icon, IconButton, Skeleton, Text, useToast } from "@chakra-ui/react";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { MdArrowBackIosNew, MdChevronLeft, MdCreditCard } from "react-icons/md";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import AccountBillingPanel from "../components/account/account_billing_panel";
import Card from "../components/card";
import ContactDiscoveryBillingCard from "../components/contact/contact_discovery_billing_card";
import ContactDiscoveryForm from "../components/contact/contact_discovery_form";
import ContactEditForm from "../components/contact/contact_edit_form";
import Contact from "../models/contact";
import appState from "../utils/app_state";
import { apiRequest, toCurrencyStr, useEnhancedToast } from "../utils/helpers";

const ContactDiscoveryPage = observer(({mode})=>{
  const [contact, setContact] = useState(undefined);
  const params = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(()=>{
    appState.needsBack(true);
    loadContact();
    window.mytoast = toast;

    return ()=>{
      appState.needsBack(false);
      contact?.unsubscribeToEvents();
    }
  }, []);

  const loadContact = async ()=>{
    // load the contact by the param id
    const contact_id = params.contactID;
    if (contact_id) {
      const c = Contact.create();
      const res = await c.load({id: contact_id});
      if (res.success) {
        c.subscribeToEvents();
        //window.contact = new Contact(resp.data);
        if (!appState.isResourceMine(c)) {
          navigate(c.view_path);
        } else {
          setContact(c);
        }
      }
    }
  };

  if (!contact) {
    return (
      <Container>
        <Box>
          <Skeleton height="8" width="50%" />
          <HStack mt="6">
            <Skeleton height="10" flex="1" />
            <Skeleton height="10" flex="1" />
          </HStack>
          <HStack mt="4">
            <Skeleton height="10" flex="1" />
            <Skeleton height="10" flex="1" />
          </HStack>

          <Skeleton mt="10" height="8" width="50%" />
          <Skeleton mt="6" height="10" flex="1" />
          <HStack mt="4">
            <Skeleton height="10" flex="1" />
            <Skeleton height="10" flex="1" />
          </HStack>
          <Skeleton mt="4" height="10" flex="1" />
        </Box>
      </Container>
    )
  }

  return (
    <Container centerContent>
      <AccountBillingPanel variant="alerts"/>
      <ContactDiscoveryBillingCard contact={contact}/>
      <ContactDiscoveryForm contact={contact}></ContactDiscoveryForm>
    </Container>
  );
});

export default ContactDiscoveryPage;
