import { FormControl, FormErrorMessage, FormLabel, Icon, Input, InputGroup, InputLeftAddon, InputLeftElement, Textarea } from "@chakra-ui/react";
import { action } from "mobx";
import { observer } from "mobx-react-lite";

const ModelFormField = observer(({type, model, field, placeholder, leftIcon, leftAddon, label, variant="default"})=>{
  let fieldErrors = model.errorsForField(field);
  let hasError = fieldErrors.length > 0;
  let errorMessage = fieldErrors.map((e)=> e.message).join(" ");

  if (variant == "floating" && !placeholder) { placeholder = " "}

  const handleChange = action((ev)=>{
    const {name, value} = ev.target;
    model.set(name, value);
  })

  // build input
  let inputElement = null;
  if (type == 'text' || type == 'email') {
    inputElement = (
      <Input type={type} placeholder={placeholder} name={field} value={model[field] || ''} onChange={handleChange} />
    )
  } else if (type == 'textarea') {
    inputElement = (
      <Textarea resize="vertical" placeholder={placeholder} name={field} value={model[field] || ''} onChange={handleChange} />
    )
  }

  if (variant == "floating") {
    if (leftIcon) {
      label = <>
        <Icon as={leftIcon} color="gray.300" mr={2} />
        {label}
      </>
    }
    inputElement = <>
      {inputElement}
      <FormLabel display="flex" alignItems="center">{label}</FormLabel>
    </>
  } else {
    // icons
    if (leftIcon) {
      inputElement = (
        <InputGroup>
          <InputLeftElement pointerEvents="none"><Icon as={leftIcon} color="gray.300" /></InputLeftElement>
          {inputElement}
        </InputGroup>
      )
    } else if (leftAddon) {
      inputElement = (
        <InputGroup>
          <InputLeftAddon children={leftAddon} />
          {inputElement}
        </InputGroup>
      )
    }
  }
  return (
    <FormControl isInvalid={hasError} variant={variant}>
      {inputElement}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
});

export default ModelFormField;