import { Box, Button, ButtonGroup, Container, Divider, Flex, Heading, HStack, Link, SimpleGrid, StackDivider, Text, useToast } from "@chakra-ui/react";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AccountBillingPanel from "../components/account/account_billing_panel";
import Card from "../components/card";
import ContactCard from "../components/contact/contact_card";
import Contact from "../models/contact";
import appState from "../utils/app_state";
import { apiRequest, toCurrencyStr, useEnhancedToast } from "../utils/helpers";

const AccountPage = observer(()=>{
  const [contacts, setContacts] = useState([]);
  const [billingPrices, setBillingPrices] = useState([]);
  const [billingPeriod, setBillingPeriod] = useState('month');

  const toast = useToast();
  const [searchParams] = useSearchParams();
  const focus = searchParams.get('focus') || 'all';

  // computeds
  const subscription = appState.currentUser?.stripe_subscription;
  const subscriptionPrimaryItem = subscription ? subscription.items.data[0] : undefined;
  const billingPricesForPeriod = billingPrices.filter((p)=> p.recurring.interval == billingPeriod);

  window.appState = appState;
  //window.plans = plans;

  useEffect(()=>{
    const email = searchParams.get("email")
    const code = searchParams.get("passcode")
    const contactID = searchParams.get("contactID")
    const contactClaimCode = searchParams.get("contactClaimCode")
    if (!appState.isLoggedIn && email && code) {
      appState.login({email, passcode: code});
    } else if (contactID && contactClaimCode) {
      appState.claimContact({id: contactID, claimCode: contactClaimCode});
    } else if (!appState.isLoggedIn) {
      window.location = "/";
    }
  }, []);

  useEffect(()=>{
    if (!appState.isLoggedIn) { return }
    loadMyContacts();
    loadBillingPlans();
  }, [appState.currentUser]);

  const loadMyContacts = action(async ()=>{
    const res = await apiRequest({
      url: "/api/contacts",
      method: 'GET',
      data: {
        filters: {
          with_user_id: appState.currentUser.id,
          not_reference: {}
        }
      }
    });
    if (res.success) {
      setContacts( res.data.map((cd)=> {return Contact.create(cd);}) );
    }
  });

  const loadBillingPlans = action(async ()=>{
    await appState.loadCurrentSubscription();
    const res = await apiRequest({
      url: "/api/users/billing_prices",
      method: "GET"
    })
    if (res.success) {
      const prices = res.data.sort((p1, p2)=> {return p1.unit_amount - p2.unit_amount;});
      setBillingPrices( prices );
    }
  });

  const isPriceActive = (price) => {
    return subscriptionPrimaryItem?.price.id == price.id;
  }

  const selectPrice = (price)=>{
    if (subscription) {
      promptUpgradeSubscription(price);
    } else {
      checkout(price);
    }
  }

  const checkout = async (price)=> {
    const res = await apiRequest({
      url: "/api/user/create_checkout_session",
      method: "POST",
      data: {id: appState.currentUser.id, price_id: price.id}
    })
    if (res.success) {
      window.location.href = res.data.session_url;
    } else if (res.errors[0].code == 'SubscriptionExistsError') {
      promptUpgradeSubscription(price);
    } else {
      appState.toastResponseError(res, {title: "Could not upgrade subscription"});
    }
  }

  const promptUpgradeSubscription = (price)=>{
    const msg = `Are you sure you want to upgrade to the ${price.product.name} plan? Your next bill may be prorated to make up any cost difference.`;
    const answer = window.confirm(msg);
    if (!answer) return;
    updateSubscription(price);
  }

  const updateSubscription = async (price)=> {
    const res = await apiRequest({
      url: "/api/user/update_subscription",
      method: "POST",
      data: {
        id: appState.currentUser.id,
        price_id: price.id,
        include: ['stripe_subscription']
      },
    })
    if (res.success) {
      appState.currentUser.handleData(res.data);
    } else {
      appState.toastResponseError(res, {title: 'Could not upgrade subscription'});
    }
  }

  const manageBilling = async ()=>{
    const res = await apiRequest({
      url: "/api/user/create_billing_session",
      method: "POST",
      data: {id: appState.currentUser.id}
    })
    if (res.success) {
      window.location.href = res.data.session_url;
    } else {
      toast({
        title: "Cannot show billing information",
        description: res.error_message,
        status: 'error'
      })
    }
  }

  const panelShown = (name)=>{
    return (focus == 'all' || focus == name);
  };

  if (!appState.isLoggedIn) {
    return (
      <Container>Attempting to sign in, please wait.</Container>
    );
  }

  return (
    <Container>
      {panelShown('cards') &&
      <>
        <Heading size="md" mb="2">My Cards</Heading>
        <SimpleGrid columns={2} spacing={4}>
          {contacts.map((c)=>(
            <ContactCard contact={c} key={c.id}/>
          ))}
        </SimpleGrid>
        <Divider variant="basic"/>
      </>
      }

      <Heading size="md" mb="2" mt={4}>My Account</Heading>
      <AccountBillingPanel/>

    </Container>
  );

});

export default AccountPage;