import { Box, Button, Container, HStack, IconButton, Skeleton, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { MdArrowBackIosNew, MdChevronLeft } from "react-icons/md";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import ContactDiscoveryForm from "../components/contact/contact_discovery_form";
import ContactEditForm from "../components/contact/contact_edit_form";
import Contact from "../models/contact";
import appState from "../utils/app_state";
import { apiRequest } from "../utils/helpers";

const ContactEditPage = observer(({mode})=>{
  const [contact, setContact] = useState(undefined);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const focus = searchParams.get('focus') || 'all';
  const navigate = useNavigate();

  useEffect(()=>{

    if (mode == 'edit') {
      appState.needsBack(true);
      loadContact();
    } else {
      let c = Contact.create();
      if (appState.isLoggedIn && searchParams.get('reference') == 'true') {
        c.set('is_reference', true);
      }
      setContact(c);
    }

    return ()=>{
      appState.needsBack(false);
    }
  }, [mode, searchParams]);

  const loadContact = async ()=>{
    // load the contact by the param id
    const contact_id = params.contactID;
    if (contact_id) {
      const c = Contact.create();
      const res = await c.load({id: contact_id});
      if (res.success) {
        //window.contact = new Contact(resp.data);
        if (!appState.isResourceMine(c)) {
          navigate(c.view_path);
        } else {
          setContact(c);
        }
      }
    }
  };

  const panelShown = (name)=>{
    return (focus == 'all' || focus == name);
  };

  if (!contact) {
    return (
      <Container>
        <Box>
          <Skeleton height="8" width="50%" />
          <HStack mt="6">
            <Skeleton height="10" flex="1" />
            <Skeleton height="10" flex="1" />
          </HStack>
          <HStack mt="4">
            <Skeleton height="10" flex="1" />
            <Skeleton height="10" flex="1" />
          </HStack>

          <Skeleton mt="10" height="8" width="50%" />
          <Skeleton mt="6" height="10" flex="1" />
          <HStack mt="4">
            <Skeleton height="10" flex="1" />
            <Skeleton height="10" flex="1" />
          </HStack>
          <Skeleton mt="4" height="10" flex="1" />
        </Box>
      </Container>
    )
  }

  return (
      <Container centerContent>
        <ContactEditForm contact={contact} mode={mode}></ContactEditForm>
      </Container>
  );
});

export default ContactEditPage;