import EventEmitter from 'events';
import { makeAutoObservable, makeObservable, runInAction } from 'mobx';
import { HelmetData } from 'react-helmet-async';
import Contact from '../models/contact';
import User from '../models/user';
import { apiRequest } from './helpers';

class AppState {
  currentUser = undefined
  siteRole = undefined
  contextLoaded = false
  backButtonVisible = false
  backButtonPath = undefined
  loginModalVisible = false
  billingResolving = false
  recentlyViewedContactIds = []
  helmetData = new HelmetData({});

  constructor() {
    makeAutoObservable(this);
    this.emitter = new EventEmitter();
    // register models
    this.registerModels([Contact, User]);
  }

  get isLoggedIn() {
    return !!this.currentUser;
  }

  get hasSiteRole() {
    return !!this.siteRole;
  }

  get activeBillingPlanKey() {
    if (!this.isLoggedIn) { return null; }
    if (this.currentUser.billing_plan_active != true) { return null; }
    return this.currentUser.billing_plan_key;
  }

  get hasActiveBillingPlan() {
    return this.activeBillingPlanKey != null;
  }

  registerModels(models) {
    for (let model of models) {
      model.eventBus = this.emitter;
    }
  }

  setCurrentUser(user) { this.currentUser = user; }
  setBillingResolving(val) { this.billingResolving = val; }

  needsBack(val, {path}={}) {
    this.backButtonVisible = val;
    if (val) {
      this.backButtonPath = path;
    } else {
      this.backButtonPath = undefined;
    }
  }

  updateTitle(str) {
    const fs = str ? `justQRMe - ${str}` : 'justQRMe';
    document.title = fs;
  }

  async loadGlobalContext() {
    const resp = await apiRequest({
      url: "/api/context",
      method: 'GET'
    })
    if (resp.success) {
      runInAction(()=>{
        this.currentUser = resp.data.user ? User.create(resp.data.user) : undefined;
        this.siteRole = resp.data.site_role;
        this.contextLoaded = true;
      })
      console.log("Context loaded.");
    }
  }

  async loadCurrentSubscription() {
    const res = await apiRequest({
      url: "/api/users",
      method: 'GET',
      data: {
        id: this.currentUser.id,
        include: ['stripe_subscription', 'billable_items']
      }
    })
    if (res.success) {
      this.currentUser.handleData(res.data);
    }
    return res;
  }

  async resolveBillingState() {
    const res = await apiRequest({
      url: "/api/user/resolve_billing_state",
      method: 'POST',
      data: {
        id: this.currentUser.id,
        modify_subscription: true,
        include: ['stripe_subscription', 'billable_items']
      },
      loading: this.setBillingResolving.bind(this)
    });
    if (res.success) {
      this.currentUser.handleData(res.data);
    } else {
      this.toastResponseError(res, {title: 'Resolve billing error'});
    }
    return res;
  }

  promptLogin() {
    this.loginModalVisible = true;
  }

  async login({email, passcode, contact}) {
    const resp = await apiRequest({
      url: "/api/login",
      method: 'POST',
      data: {
        email: email,
        passcode: passcode,
      }
    });
    this.toastResponse(resp, {errorTitle: "Could not sign in."});
    if (resp.success) {
      await this.loadGlobalContext();
      if (contact) {
        window.location = contact.view_path;
      } else {
        window.location = "/account";
      }
    }
  }

  async claimContact({id, claimCode}) {
    const resp = await apiRequest({
      url: "/api/claim_contact",
      method: 'POST',
      data: {
        contact_id: id,
        contact_claim_code: claimCode,
      }
    });
    this.toastResponse(resp, {errorTitle: "Could not claim contact."});
    if (resp.success) {
      await this.loadGlobalContext();
      let contact = Contact.create(resp.data);
      window.location = contact.view_path;
    }
  }

  async logout() {
    const resp = await apiRequest({
      url: "/api/logout",
      method: 'POST',
      data: {}
    })
    if (resp.success) {
      this.currentUser = undefined;
      this.contextLoaded = false;
      window.location = "/";
    }
  }

  isResourceMine(c) {
    if (!this.isLoggedIn) return false;
    return c.user_id == this.currentUser.id;
  }

  getRecentlyViewedContactIds() {
    const jd = localStorage.getItem("recentlyViewedContactIds");
    if (jd) { 
      this.recentlyViewedContactIds = JSON.parse(jd);
    }
    return this.recentlyViewedContactIds;
  }

  addRecentlyViewedContact(contact) {
    const cids = this.getRecentlyViewedContactIds();

    // skip if contact owned by user
    if (this.isLoggedIn && contact.user_id == this.currentUser.id) { return cids; }

    let ret = [];
    let cm = {};
    for (let cid of [contact.id].concat(this.recentlyViewedContactIds)) {
      if (cm[cid] || ret.length >= 20) { break; }
      ret.push(cid);
      cm[cid] = true;
    };
    localStorage.setItem("recentlyViewedContactIds", JSON.stringify(ret));
    return ret;
  }

  toastResponse(res, {successTitle, errorTitle}) {
    if (res.success && successTitle) {
      this.emitter.emit("app.toast", {
        title: successTitle,
        status: 'success'
      });
    } else if (!res.success) {
      this.toastResponseError(res, {title: errorTitle})
    }
  }

  toastResponseError(res, {title}) {
    const opts = {
      title: title,
      description: res.error_message,
      status: 'error'
    }
    this.emitter.emit("app.toast", opts);
  }

  confirm(msg) {
    this.emitter.emit("app.confirm", msg);
  }

}
const appState = new AppState();
export default appState;