import { Avatar, Badge, Box, Button, Container, Flex, Grid, Heading, HStack, Icon, IconButton, Image, Popover, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Portal, Skeleton, SkeletonCircle, SkeletonText, Spinner, StackDivider, Text, Tooltip, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { MdBookmark, MdBookmarkAdd, MdEdit, MdIosShare, MdLocationOn, MdPublic, MdQrCode, MdQrCodeScanner, MdShare, MdSms } from "react-icons/md";
import { FaRegAddressCard } from "react-icons/fa";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Card from "../components/card";
import Contact from "../models/contact";
import appState from "../utils/app_state";
import { apiRequest, pluralize } from "../utils/helpers";
import { IoLocationOutline, IoLocationSharp } from "react-icons/io5";
import { Helmet } from "react-helmet-async";
import Joyride, {STATUS as JOYRIDE_STATUS} from "react-joyride";

const ContactPage = observer(()=> {
  const [contact, setContact] = useState(undefined);
  const [contactSummary, setContactSummary] = useState(undefined);
  const [avatarUploading, setAvatarUploading] = useState(false);
  const avatarInput = useRef(null);

  const params = useParams();
  const navigate = useNavigate();

  const isMine = appState.isLoggedIn && (contact?.user_id == appState.currentUser.id);
  const linksShown = contact && (contact.website || contact.has_social);

  const guideSteps = [
    {
      target: '.jqr-contact-banner-card',
      content: (<div><strong>This your new digital business card.</strong> It can show all your important personal or business information.</div>),
      disableBeacon: true
    },
    {
      target: '.jqr-contact-qr-code',
      content: (<div>Tap here to show a larger version of your QR code for others to scan with their phone cameras.</div>),
      disableBeacon: true
    },
    {
      target: '.jqr-contact-save-button',
      content: (<div>Other people can simply click here to save this contact into to their mobile phones.</div>),
      disableBeacon: true
    },
    {
      target: '.jqr-contact-edit-button',
      content: (<div>Edit your card here at any time to make sure everyone has your latest information.</div>),
      disableBeacon: true
    },
    {
      target: '.jqr-contact-discovery-button',
      content: (<div>Here you can make your card discoverable in searches and even promote it to be shown at the top of results.</div>),
      disableBeacon: true
    },
    {
      target: '.jqr-contact-qr-button',
      content: (<div><strong>Save your QR card to your home screen</strong> so that you can quickly share it with others by a single tap.</div>),
      disableBeacon: true
    }
  ];

  const shouldShowGuide = isMine && appState.currentUser?.is_recently_created && (localStorage.getItem("contactGuideShown") == null)

  useEffect(()=>{
    loadContact();
  }, []);

  const loadContact = async ()=> {
    // load the contact by the param id
    const c = Contact.create();
    const res = await c.loadBy({id: params.contactID, shortKey: params.contactShortKey, include: ['qr_code', 'user_state']})
    if (res.success) {
      setContact(c);
      loadContactSummary(c);
      appState.addRecentlyViewedContact(c);
    }
  }

  const loadContactSummary = async (contact)=> {
    const res = await apiRequest({
      url: "/api/contact/summary",
      method: 'GET',
      data: {id: contact.id}
    });
    if (res.success) {
      setContactSummary(res.data);
    }
  }

  const handleAvatarClick = ()=> {
    if (isMine) { avatarInput.current.click(); }
  }

  const handleFileSelected = async (file)=> {
    // eventually show updating toast during upload
    const fd = new FormData();
    fd.append('id', contact.id);
    fd.append('avatar', file);
    const res = await apiRequest({
      url: "/api/contact/update_avatar",
      method: 'POST',
      loading: setAvatarUploading,
      data: fd
    });
    if (res.success) {
      contact.handleData(res.data);
    }
  }

  const toggleBookmark = async() => {
    const res = await apiRequest({
      url: "/api/contact/bookmark",
      method: 'POST',
      data: {
        id: contact.id,
        bookmarked: !contact.user_bookmarked,
        include: ["user_state"]
      }
    });
    if (res.success) {
      contact.handleData(res.data);
    } else {
      appState.toastResponse(res, {errorTitle : "Could not remove bookmark"})
    }
  }

  const editContact = ()=> {
    navigate(contact.edit_path);
  }
  const editContactDiscovery = ()=> {
    navigate(contact.discovery_path);
  }

  const displayQRCode = ()=> {
    navigate(contact.qr_path);
  }

  const handleGuidedTourCallback = (data)=> {
    if ([JOYRIDE_STATUS.FINISHED, JOYRIDE_STATUS.SKIPPED].includes(data.status)) {
      localStorage.setItem("contactGuideShown", "true");
    }
  }

  const shareContact = ()=> {
    navigator.share({
      title: document.title,
      url: window.location.href
    });
  }

  if (!contact) {
    return (
      <Container>
        <Box>
          <SkeletonCircle size="32"/>
          <Skeleton mt="4" height="8" width="50%" />
          <SkeletonText mt="4" noOfLines={2} />
          <Skeleton mt="8" height="6" width="40%" />
          <SkeletonText mt="8" noOfLines={4} spacing="4" />
          <SkeletonText mt="8" noOfLines={4} spacing="4" />
          <SkeletonText mt="8" noOfLines={4} spacing="4" />
        </Box>
      </Container>
    )
  }

  return (
    <Container>
      <Helmet helmetData={appState.helmetData}>
        <title>justQRMe - {contact.full_name}</title>
      </Helmet>
      <Card padding="0" overflow="hidden" className="jqr-contact-banner-card">
        <Box h="150px" bgGradient="linear(to-b, #dca4f8 0%, #6f84ff 95%)" bgImage="url('/images/milad-fakurian-unsplash-web.jpg')" bgPosition="center" bgSize="cover" bgRepeat="no-repeat" pos="relative">
          <Avatar bg="gray.200" color="gray.700" name={contact.full_name} src={contact.avatar_url} size='2xl' showBorder borderWidth="5px" pos="absolute" bottom="-25px" left="15px" onClick={handleAvatarClick} />
          <input type="file" ref={avatarInput} onChange={(ev)=>{handleFileSelected(ev.target.files[0])}} style={{display: 'none'}}/>
          <Image src={contact.qr_code} boxSize="100px" position="absolute" top="25px" right="20px" borderRadius="10px" onClick={displayQRCode} className="jqr-contact-qr-code"></Image>
          { avatarUploading && <Spinner size="lg" pos="absolute" bottom="15px" right="15px" /> }
        </Box>

        <Box padding={4} pos="relative">
          <Heading size="xl" mt={30} fontWeight="bold">{contact.full_name}</Heading>
          <Heading size="md" fontWeight="normal" fontStyle="italic">{contact.full_company_title}</Heading>
          <VStack align={'flex-start'} mt={5}>
            <Box>
              {contact.bio}
            </Box>
            {contact.has_city_state && (<HStack>
              <Icon as={MdLocationOn} />
              <Text>{contact.city}, {contact.state}</Text>
            </HStack>)}
            <Box>
              {
                contact.discovery_tags.map((t)=>
                  <Badge key={t} variant="outline" colorScheme="gray" mr={2}>#{t}</Badge>
                )
              }
            </Box>
            <Box>
              <DiscoverabilityBadge contact={contact} />
              {contactSummary?.bookmarks_count > 0 && <Tooltip label={`${pluralize(contactSummary.bookmarks_count, 'person has', 'people have')} bookmarked this card`}>
                <Badge colorScheme="blue" ml={4}>
                  <Icon as={MdBookmark} boxSize={4} mr={1} verticalAlign="middle"/>
                  <Text display="inline" verticalAlign="middle">{contactSummary.bookmarks_count}</Text>
                </Badge>
              </Tooltip>}
            </Box>
          </VStack>
          <Box position="absolute" top="15px" right="15px">
            {isMine && (
              <>
                <Tooltip label="Edit Contact" hasArrow>
                  <IconButton variant="outline" colorScheme="gray" icon={<MdEdit/>} onClick={editContact} mr={2} className="jqr-contact-edit-button" />
                </Tooltip>
                <Tooltip label="Manage Contact Discovery" hasArrow>
                  <IconButton variant="outline" colorScheme="gray" icon={<MdPublic/>} onClick={editContactDiscovery} className="jqr-contact-discovery-button" />
                </Tooltip>
              </>
            )}
          </Box>
        </Box>
      </Card>

      <Flex mb={5} gap={2}>
        <a target="_blank" href={`/api/files/contact/${contact.id}.vcf`} download style={{flex: 1}}>
          <Button colorScheme="blue" size="lg" w="100%" leftIcon={<FaRegAddressCard size={24} />} className="jqr-contact-save-button">Save Contact</Button>
        </a>
        {navigator.share && <IconButton colorScheme="blue" size="lg" icon={<MdIosShare size={24} />} onClick={shareContact} />}
        <IconButton colorScheme="blue" size="lg" icon={<MdQrCodeScanner size={24} />} onClick={displayQRCode} className="jqr-contact-qr-button" />
        {appState.isLoggedIn && <IconButton colorScheme={contact.user_bookmarked ? "green" : "blue"} size="lg" icon={<MdBookmarkAdd size={24} />} onClick={toggleBookmark}/>}
      </Flex>

      {linksShown && (<Card>
        <Heading size="md" mb={5}>Links + Social</Heading>
        <VStack divider={<StackDivider borderColor='gray.200'/>} align='stretch'>
          {contact.website && <ContactLinkRow contact={contact} attrKey="website"></ContactLinkRow>}
          {contact.socials_given.map((social_key)=>
            <ContactLinkRow contact={contact} attrKey={social_key} key={social_key}></ContactLinkRow>
          )}
        </VStack>
      </Card>)}

      <Card>
        <Heading size="md" mb={5}>Phone + Email</Heading>
        <VStack divider={<StackDivider borderColor='gray.200'/>} align='stretch'>
          {contact.phones_given.map((social_key)=>
            <ContactLinkRow contact={contact} attrKey={social_key} key={social_key}></ContactLinkRow>
          )}
          {contact.has_email && (
            <ContactLinkRow contact={contact} attrKey="email"></ContactLinkRow>
          )}
        </VStack>
      </Card>

      {contact.has_city_state && (<Card>
        <Heading size="md" mb={5}>Location</Heading>
        <HStack mb={5}>
          <Icon as={IoLocationSharp} boxSize={10}/>
          <Box>
            <Text>{contact.street_1}</Text>
            <Text>{contact.street_2}</Text>
            <Text>{contact.city}, {contact.state} {contact.postal_code}</Text>
          </Box>

        </HStack>
        <iframe
          width="100%"
          height="400"
          style={{border:0}}
          loading="lazy"
          allowFullScreen
          src={contact.google_map_url}>
        </iframe>
      </Card>)}

      {shouldShowGuide && <Joyride steps={guideSteps} continuous={true} callback={handleGuidedTourCallback} showSkipButton={true} />}
    </Container>
  )
});

export default ContactPage;

const ContactLinkRow = ({contact, attrKey})=>{
  const ctd = Contact.CONTACT_ATTRS.findForKey(attrKey);
  const icon = ctd.icon;
  const name = ctd.name;
  let value = contact[attrKey];
  let url = value;
  let rightEl = null;

  // value
  if (ctd.type == "social" && ctd.urlPrefix != "") {
    value = `@${value}`;
  } 

  // url
  if (ctd.type == "phone") {
    url = `tel: ${value}`;
    if (attrKey == 'mobile_phone') {
      rightEl = (
        <IconButton icon={<MdSms/>} onClick={(e)=>{e.stopPropagation(); window.open(`sms: ${value}`, '_blank')}}/>
      )
    }
  } else if (ctd.key == "website" && !value.startsWith("http")) {
    url = `https://${value}`;
  } else if (!value.includes("/")) {
    url = (ctd.urlPrefix || "") + url + (ctd.urlSuffix || "");
  }
  return (
    <a target="_blank" href={url}>
      <HStack>
        <Icon as={icon} boxSize={10}/>
        <Box flex="1">
          <Heading size="md">{ctd.name}</Heading>
          <Text>{value}</Text>
        </Box>
        {rightEl}
      </HStack>
    </a>
  )
}

const DiscoverabilityBadge = observer(({contact})=>{
  
  const configureDiscoverySettings = ()=>{
    navigate(contact.discovery_path);
  }

  let text = "Not Discoverable";
  let scheme = 'red';
  let summary = "This contact cannot be found by others on the search page.";
  let action_text = "Set Tags and Locations";
  let action_fn = configureDiscoverySettings;

  const isMine = contact.user_id == appState.currentUser?.id;
  const navigate = useNavigate();

  const bpk = appState.activeBillingPlanKey;
  const disc_cfg = contact.discovery_configured;
  const disc_state = contact.discovery_state;
  const disc_bl = contact.discovery_boost_level;
  if (disc_state == 'discoverable' && disc_cfg) {
    if (disc_bl > 0) {
      text = `Promoted ×${disc_bl}`;
      scheme = 'blue';
      summary = "This contact can be found on the search page and is promoted at the top of the results.";
      action_fn = null;
    } else {
      text = "Discoverable";
      scheme = 'green';
      summary = "This contact can be found by others on the search page.";
      action_text = "Add Promoted Boosts"
    }
  } else if (disc_cfg) {
    action_text = "Enable Contact Discovery";
  } else {
    // nothing for now
  }

  return (
    <Popover>
      <PopoverTrigger>
        <Badge colorScheme={scheme}>
          <Icon as={MdPublic} boxSize={4} mr={1} verticalAlign="middle"/>
          <Text display="inline" verticalAlign="middle">{text}</Text>
        </Badge>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverCloseButton/>
          <PopoverHeader>Contact Discoverability</PopoverHeader>
          <PopoverBody>
            <Text>{summary}</Text>
          </PopoverBody>
          {isMine && action_fn && (<PopoverFooter>
            <Button colorScheme="blue" onClick={action_fn}>{action_text}</Button>
          </PopoverFooter>)}
        </PopoverContent>
      </Portal>
    </Popover>
  )
});