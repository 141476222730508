import { Box, Flex, Heading, HStack, Icon, IconButton, Input, Text } from "@chakra-ui/react";
import { CreatableSelect, AsyncSelect } from "chakra-react-select";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { MdLocationPin, MdPublic, MdSearch, MdTag } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { apiRequest } from "../utils/helpers";
import Card from "./card";

const SearchBox = observer(({initialTags, initialLocation})=>{
  const [tags, setTags] = useState();
  const [location, setLocation] = useState();
  const navigate = useNavigate();

  const selectedTags = ()=> (tags || initialTags || []);
  const selectedLocation = ()=> (location || initialLocation);

  const tagOptions = ()=> {
    return selectedTags().map((t)=>({value: t, label: t}));
  };

  const handleTagsChanged = action((val)=> {
    console.log("Handling tags changed");
    setTags(val.map((opt)=>opt.value));
  });

  const handleLocationChanged = action((val)=>{
    setLocation(val);
  })

  const loadLocationOptions = async (inputValue)=>{
    const res = await apiRequest({
      url: "/api/locations/search",
      method: 'GET',
      data: {
        query: inputValue,
        where: {country: "United States"},
        match: 'word_start',
        limit: 10,
        fields: ['city', 'admin']
      }
    });
    return res.data;
  }

  const search = ()=> {
    const tags_str = selectedTags().join(",");
    const sl = selectedLocation();
    let loc_str = ""
    if (sl) {
      loc_str = sl.id;
    }
    const path = `/search?index=search&tags=${encodeURI(tags_str)}&loc=${encodeURI(loc_str)}`
    navigate(path);
  };

  return (
    <Card minW="full">
      <HStack spacing='4' mb={4}>
        <Icon as={MdPublic} w={8} h={8} color="green" verticalAlign='middle' />
        <Heading size='md' mb="2">
          Find Someone
        </Heading>
      </HStack>

      <Flex gap={4} justifyContent="space-between" wrap="wrap">
        <Flex direction="row" flex="1" alignItems="center" gap={2}>
          <Icon as={MdTag} fontSize="20px" color="gray.500" />
          <Box minW="200" flex="1">
            <CreatableSelect

              isMulti
              placeholder="realtor, hair stylist, ..."
              value={tagOptions()}
              onChange={handleTagsChanged}
              components={{DropdownIndicator: ()=>null, IndicatorSeparator: ()=>null}}/>
            </Box>
        </Flex>
        <Flex direction="row" gap={2} justifyContent="space-between" alignItems="center" flex="1">

          <Icon as={MdLocationPin} fontSize="20px" color="gray.500" />
          <Box minW="200" flex="1">
            <AsyncSelect
              placeholder="Los Angeles, CA"
              getOptionLabel={(opt)=>opt.display_name}
              getOptionValue={(opt)=>opt.id}
              value={selectedLocation()}
              onChange={handleLocationChanged}
              loadOptions={loadLocationOptions}
              components={{DropdownIndicator: ()=>null, IndicatorSeparator: ()=>null}}/>
          </Box>

          <IconButton icon={<MdSearch/>} colorScheme="green" fontSize="20px" onClick={search} />
        </Flex>

      </Flex>

    </Card>
  )
})

export default SearchBox;