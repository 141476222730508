import { Alert, Box, Button, ButtonGroup, Heading, HStack, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text, useToast } from "@chakra-ui/react";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import { clone } from "mobx-state-tree";
import { useEffect, useState } from "react";
import { MdCreditCard } from "react-icons/md";
import appState from "../../utils/app_state";
import { apiRequest, toCurrencyStr, toRecurringCurrencyStr } from "../../utils/helpers";
import Card from "../card";

const ContactDiscoveryBillingCard = observer(({contact})=>{
  const [localContact] = useState(clone(contact));
  const [pricing, setPricing] = useState();
  const [isEditModalShown, setEditModalShown] = useState(false);
  const [sliderValue, setSliderValue] = useState(5);

  const toast = useToast();

  let title = "Discovery Subscription";
  const discoveryStateOptions = [
    {key: 'discoverable', title: 'Discoverable'},
    {key: 'private', title: 'Private'},
  ]

  useEffect(()=>{
    loadPricing();
  }, []);

  const pricingStr = (key)=>{
    if (!pricing) return 'Loading';
    const price = pricing[key];
    return toRecurringCurrencyStr(price);
  }

  const loadPricing = action(async ()=>{
    const res = await apiRequest({
      url: "/api/users/billing_prices",
      method: "GET",
    });
    if (res.success) {
      const pricing = {
        discoverable: res.data.find((price)=> price.product.metadata.key == "discoverable" && price.recurring.interval == 'month'),
        promoted_boost: res.data.find((price)=> price.product.metadata.key == "promoted_boost" && price.recurring.interval == 'month'),
      }
      window.pricing = pricing;
      setPricing(pricing);
    }
  });

  const activateDiscovery = async ()=> {
    localContact.set('discovery_state', 'discoverable');
    localContact.set('discovery_boost_level', 0);
    await updateDiscoveryState();
  }

  const promptUpdateDiscoveryState = ()=>{
    appState.confirm({
      title: "Change card discovery?",
      description: "Are you sure you want to change the discovery settings for this card? This will automatically update your subscription and your monthly billing amount may change.",
      onConfirm: ()=>{updateDiscoveryState();}
    })
  }

  const updateDiscoveryState = async ()=>{
    const res = await localContact.save({
      url: "/api/contact/update_discovery_state",
      withFields: ['discovery_state', 'discovery_boost_level']
    });
    if (res.success) {
      setEditModalShown(false);
      // open checkout if no subscription
      await appState.loadCurrentSubscription();
      if (appState.currentUser.is_billable && !appState.currentUser.stripe_subscription) {
        checkout();
      } else {
        toast({
          title: "Contact discovery settings saved",
          status: 'success'
        })
      }
    } else {
      appState.toastResponseError(res, {title: "Could not make card discoverable"});
    }

  };

  const checkout = async ()=>{
    const res = await apiRequest({
      url: "/api/user/create_checkout_session",
      method: "POST",
      data: {
        id: appState.currentUser.id,
        //price_id: discoveryPrice.id,
      }
    })
    if (res.success) {
      window.location.href = res.data.session_url;
    } else {
      appState.toastResponseError(res, {title: "Could not upgrade subscription"});
    }
  }

  const localTotalAmount = ()=>{
    let amount = null;
    if (!pricing) return amount;
    if (localContact.discovery_state == 'discoverable') {
      amount = pricing.discoverable.unit_amount + pricing.promoted_boost.unit_amount * localContact.discovery_boost_level;
    } else {
      amount = 0;
    }
    return amount;
  }

  let contents = null;
  if (contact.discovery_state != 'discoverable') {
    contents = (
      <>
        <Text fontSize='xl' mb={4}>Want this card to be found when people <strong>search for your tags</strong> in <strong>your location</strong>? Tap the button below to make your card discoverable immediately.</Text>


        <Button colorScheme="green" w="full" onClick={activateDiscovery} isLoading={localContact.is_saving}>
          Activate Discovery - {pricingStr('discoverable')}
        </Button>
      </>
    )

  } else {
    contents = (
      <>
        <Box mb="4">
          <Heading size="md">Discovery State</Heading>
          <Text>
            This card is <Text as="span" color="green" display="inline" fontWeight="bold">{contact.discovery_state}</Text> in search results for {pricingStr('discoverable')}.
          </Text>
        </Box>
        <Box mb="4">
          <Heading size="md">Promoted Boosts</Heading>
          <Alert mb="2">
            <Text><strong>What are promoted boosts?</strong> Promoted boosts make your card show up higher in search results. The more boosts you have, the higher you rank.</Text>
          </Alert>
          <Text>
            This card currently has <Text as="span" color="green" display="inline" fontWeight="bold">{contact.discovery_boost_level} promoted boosts</Text> for {pricingStr('promoted_boost')} each.
          </Text>
        </Box>
        <Button colorScheme="green" w="full" onClick={()=>setEditModalShown(true)}>Edit Settings</Button>
        <Modal isOpen={isEditModalShown} onClose={()=>setEditModalShown(false)}>
          <ModalOverlay/>
          <ModalContent>
            <ModalHeader>Edit Discovery State</ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
              <Box mb={4}>
                <Heading size="sm" mb={2}>Discovery State</Heading>
                <ButtonGroup variant="solid" isAttached>
                  {discoveryStateOptions.map( (opt)=>(
                    <Button key={opt.key} colorScheme={localContact.discovery_state == opt.key ? 'green' : 'blackAlpha'} onClick={()=>localContact.set('discovery_state', opt.key)}>{opt.title}</Button>
                  ))}
                </ButtonGroup>
                {localContact.discovery_state == 'discoverable' && <Text color="gray.500" fontSize="sm" mt={1}>{pricingStr('discoverable')} for Discoverable</Text>}
              </Box>
              {localContact.discovery_state == 'discoverable' && (<Box mb={4} >
                <Heading size="sm" mb={2}>Promoted Boosts</Heading>
                <Slider max={50} value={localContact.discovery_boost_level} onChange={(val)=>{localContact.set('discovery_boost_level', val)}}>
                  <SliderTrack><SliderFilledTrack/></SliderTrack>
                  <SliderThumb boxSize="32px" fontWeight="bold">{localContact.discovery_boost_level}</SliderThumb>
                </Slider>
                {localContact.discovery_boost_level > 0 && <Text color="gray.500" fontSize="sm">Each promoted boost is {pricingStr('promoted_boost')}</Text>}
              </Box>)}
              <Text>Total Monthly Charge For This Card: <strong>{toCurrencyStr(localTotalAmount())}</strong></Text>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="green" width="full" onClick={promptUpdateDiscoveryState} isLoading={localContact.is_saving}>Save Settings</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )

  }

  return (
    <Card minW="full">
      <HStack spacing='4' mb={4}>
        <Icon as={MdCreditCard} w={8} h={8} color="green" verticalAlign='middle' />
        <Heading size='md' mb="2">
          {title}
        </Heading>
      </HStack>

      {contents}

    </Card>
  );
});

export default ContactDiscoveryBillingCard;
