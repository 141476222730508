//import styles from '../styles/Home.module.css'
import Contact from '../models/contact'
import { Input, Button, Container, Box, Heading, HStack, VStack, Text, Icon, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Link, InputLeftElement, InputGroup, SimpleGrid, Center } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { action, observable } from 'mobx'
import Card from '../components/card'
import { IoQrCodeOutline, IoLogoLinkedin, IoLogoTwitter, IoLogoInstagram, IoPhonePortrait, IoHome, IoBusiness, IoMail, IoGlobe, IoLogoTiktok, IoLogoSnapchat, IoLogoYoutube, IoLogoFacebook } from 'react-icons/io5'
import { MdAccountCircle, MdAddToHomeScreen, MdContactPhone, MdContactSupport, MdQrCodeScanner } from 'react-icons/md'
import { useNavigate, useSearchParams } from 'react-router-dom'
import SearchBox from '../components/search_box'
import ContactEditForm from '../components/contact/contact_edit_form'

const HomePage = observer(()=>{

  const [contact, setContact] = useState(()=> Contact.create({}));

  const [searchParams] = useSearchParams();

  // parse url on mount
  useEffect(()=>{
    console.log("Loading contact from url");
    const contact_data_uri = searchParams.get('contact');
    if (contact_data_uri) {
      //contact.fromJS(contact_data);
      setContact( Contact.fromDataURI(contact_data_uri) );
      //console.log(contact);
    }
  }, []);

  return (
    <div>
      <Container centerContent>

        <Card padding="0" overflow="hidden" w="100%" textAlign="center">
          <Box bgImage="url('/images/antenna-ZDN-G1xBWHY-unsplash-web.jpg')" bgPosition="center" bgSize="cover" pos="relative" textColor="white" py="8" px="4" textAlign="center">
            <Box position="absolute" top="0" bottom="0" left="0" right="0" bgColor="rgba(0,0,0,0.3)" ></Box>
            <Heading size="xl" mb="4" pos="relative">No more printed business cards.</Heading>
            <Heading size="md" pos="relative">Create your virtual QR card in 30 seconds.</Heading>
          </Box>
          <Box padding="4">
            <Heading size="md">Works with the iPhone and Android camera app.<br/>It's as simple as:</Heading>
          </Box>
          <HStack padding="4" alignItems="flex-start">
            <VStack w="32%">
              <Icon as={MdContactPhone} boxSize="16"/>
              <Text>Create Your Card</Text>
            </VStack>
            <VStack w="32%">
              <Icon as={MdAddToHomeScreen} boxSize="16"/>
              <Text>Add it to your home screen</Text>
            </VStack>
            <VStack w="32%">
              <Icon as={MdQrCodeScanner} boxSize="16"/>
              <Text>Open your card and let them scan it with their camera</Text>
            </VStack>
          </HStack>
        </Card>

        <ContactEditForm contact={contact} mode="create"></ContactEditForm>

      </Container>
    </div>
  )
});

export default HomePage;

export async function getServerSideProps(context) {
  const contact_data_uri = context.query.contact
  var contact, contact_data = null;
  if (contact_data_uri) {
    contact_data = JSON.parse(decodeURI(atob(contact_data_uri)));
    contact = new Contact(contact_data);
  }
  return {
    props: {
      contactData: contact_data
    }
  }
}