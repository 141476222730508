import { Input, Button, Container, Box, Flex, Heading, HStack, VStack, Text, Icon, SimpleGrid, Menu, MenuButton, MenuList, MenuItem, IconButton, MenuDivider, useToast, AlertDialog, AlertDialogOverlay, AlertDialogHeader, AlertDialogCloseButton, AlertDialogBody, AlertDialogFooter, AlertDialogContent } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { IoQrCodeOutline } from 'react-icons/io5'
import { MdAccountCircle, MdAdd, MdArrowBackIosNew, MdBookmark, MdList, MdLogin, MdLogout, MdOutlineManageAccounts, MdRecentActors, MdSearch } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import appState from '../utils/app_state'

const HeaderBar = observer(()=>{
  //window.appState = appState;
  const navigate = useNavigate();
  const toast = useToast();
  const [confirmMessage, setConfirmMessage] = useState();

  useEffect(()=>{
    appState.emitter.on("app.toast", toast);
    appState.emitter.on("app.confirm", setConfirmMessage);
    return ()=>{
      appState.emitter.off("app.toast", toast);
      appState.emitter.off("app.confirm", setConfirmMessage);
    }
  }, []);

  const goBack = ()=>{
    const path = appState.backButtonPath || -1
    navigate(path);
  };
  const goSearch = ()=>{
    navigate("/search");
  }
  const guestMenuList = (
    <MenuList>
      <Link to="/create"><MenuItem icon={<MdAdd/>}>Create My QR Card</MenuItem></Link>
      <MenuDivider/>
      <Link to="/search?index=recentlyViewed"><MenuItem icon={<MdRecentActors/>}>Recently Viewed Contacts</MenuItem></Link>
      <MenuItem onClick={() => appState.promptLogin()} icon={<MdLogin/>}>Sign In</MenuItem>
    </MenuList>
  );
  const userMenuList = (
    <MenuList>
      <Link to="/account"><MenuItem icon={<MdAccountCircle/>}>My Cards</MenuItem></Link>
      <Link to="/search?index=bookmarked"><MenuItem icon={<MdBookmark/>}>Bookmarked Cards</MenuItem></Link>
      <Link to="/search?index=recentlyViewed"><MenuItem icon={<MdRecentActors/>}>Recently Viewed Cards</MenuItem></Link>
      <MenuDivider/>
      <Link to="/create"><MenuItem icon={<MdAdd/>}>Add QR Card</MenuItem></Link>
      <MenuDivider/>
      <MenuItem onClick={() => appState.logout() } icon={<MdLogout/>}>Sign Out</MenuItem>
    </MenuList>
  );
  let confirmDialog = null;
  let confirmCancelRef = useRef();
  if (confirmMessage) {
    const confirmFn = ()=>{
      const fn = confirmMessage.onConfirm;
      setConfirmMessage(null);
      fn();
    }
    confirmDialog = (<AlertDialog isOpen={confirmMessage} leastDestructiveRef={confirmCancelRef} onClose={()=>setConfirmMessage(null)}>
      <AlertDialogOverlay/>
      <AlertDialogContent>
        <AlertDialogHeader>{confirmMessage.title || 'Confirm?'}</AlertDialogHeader>
        <AlertDialogCloseButton/>
        <AlertDialogBody>
          {confirmMessage.description}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={confirmCancelRef} onClick={()=>{ setConfirmMessage(null)} }>
            No
          </Button>
          <Button colorScheme="green" onClick={confirmFn} ml={3}>
            Yes
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>)
  }
  return (
    <Box bg='white' mb="5">
      <Container position="relative">
        {confirmDialog}
        <Flex position="absolute" top="0px" left="7px" height="100%">
        {appState.backButtonVisible && 
          <IconButton
            icon={<MdArrowBackIosNew/>}
            variant="ghost"
            fontSize="32px"
            onClick={goBack}
          />}
        <IconButton
          icon={<MdSearch/>}
          variant="ghost"
          fontSize="32px"
          onClick={goSearch}
        />
        </Flex>
        <Box textAlign="center" width="100%">
          <Link to="/">
            <Heading fontFamily="Abel" fontWeight={400}>
              <Icon as={IoQrCodeOutline} w={5} h={5} mr={2} />
              justQRme
            </Heading>
          </Link>
        </Box>
        <Flex align="center" justify="right" position="absolute" top="0px" right="7px" height="100%">
          <Menu computePositionOnMount={true}>
            <MenuButton as={IconButton}
              icon={<MdOutlineManageAccounts/>}
              variant="ghost"
              fontSize="32px"
            />
            {appState.isLoggedIn ? userMenuList : guestMenuList}
          </Menu>
        </Flex>
      </Container>
    </Box>
  )
});

export default HeaderBar;