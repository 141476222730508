import { Alert, AlertIcon, Button, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Text, useToast, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import appState from "../utils/app_state";
import { apiRequest } from "../utils/helpers";

export default function LoginView({initialEmail, initialContact}) {
  const [email, setEmail] = useState(initialEmail || '');
  const [passcode, setPasscode] = useState('');
  const [contact, setContact] = useState(initialContact);
  const [loginLinkSent, setLoginLinkSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const toast = useToast();

  const navigate = useNavigate();

  async function sendLoginLink() {
    const resp = await apiRequest({
      url: "/api/users/request_login",
      method: 'POST',
      data: {
        email: email,
        contact: contact?.toJS(),
      },
      loading: setLoading
    });
    if (resp.success) {
      setLoginLinkSent(true);
      console.log(resp.data);
    } else {
      toast({
        status: 'error',
        title: "Email not found",
        description: "No account can be found with this email. Please create a contact first from the home page."
      })
    }
  }

  async function login() {
    await appState.login({email, passcode, contact});
  }

  return (
    <ModalContent>
      <ModalHeader>
        Sign In
      </ModalHeader>
      <ModalCloseButton/>
      <ModalBody>
        <VStack>
          <Input type="email" placeholder="Email address" value={email} onChange={(e)=>setEmail(e.target.value)} />
          { loginLinkSent ? (
            <>
              <Input type="password" placeholder="Passcode" value={passcode} onChange={(e)=>setPasscode(e.target.value)} />
              <Alert status="info">
                <AlertIcon/>
                <Text><strong>Check your email for the passcode. </strong>There is also a sign in link in the email.</Text>
              </Alert>
              <Button colorScheme="blue" onClick={login} isLoading={loading}>Login</Button>
            </>
          ) : (
            <>
              <Button colorScheme="blue" onClick={sendLoginLink} isLoading={loading}>Send Login Link</Button>
              <Button variant="link" size="sm" onClick={()=>setLoginLinkSent(true)}>I already received a code</Button>
            </>
          ) }
        </VStack>
      </ModalBody>
    </ModalContent>

  );
}