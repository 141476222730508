//import styles from '../styles/Home.module.css'
import Contact from '../../models/contact'
import { Input, Button, Container, Box, Heading, HStack, VStack, Text, Textarea, Icon, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Link, InputLeftElement, InputGroup, SimpleGrid, useToast, ButtonGroup, Badge, Alert, AlertTitle, AlertDescription, AlertIcon, Tooltip } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { action, observable } from 'mobx'
import Card from '../card'
import { IoQrCodeOutline, IoLogoLinkedin, IoLogoTwitter, IoLogoInstagram, IoPhonePortrait, IoHome, IoBusiness, IoMail, IoGlobe, IoLogoTiktok, IoLogoSnapchat, IoLogoYoutube, IoLogoFacebook } from 'react-icons/io5'
import { MdAccountCircle, MdContactSupport, MdHelp, MdHelpOutline, MdQuestionAnswer } from 'react-icons/md'
import { useNavigate, useSearchParams, Link as RouterLink } from 'react-router-dom'
import { apiRequest } from '../../utils/helpers'
import appState from '../../utils/app_state'
import ModelFormField from '../model_form_field'

const ContactEditForm = observer(({contact, mode})=>{

  const [socialState, setSocialState] = useState({linkedin: true, twitter: true, instagram: true});

  const navigate = useNavigate();
  const toast = useToast();

  const socials = Contact.SOCIAL_CONTACT_ATTRS;

  let title = "Create Your QR Card";
  let buttonTitle = "View QR Card";
  if (mode == 'edit') {
    title = "Edit Your Contact Profile"
    buttonTitle = "Save Changes"
  } else if (mode == 'create' && contact.is_reference) {
    title = "Create a QR Card For Someone"
  }

  const handleContactChange = action((e) => {
    //console.log(e);
    const {name, value} = e.target;
    contact[name] = value;
    //console.log(contact);
  });

  function buildQRCode(e) {
    //console.log("The first name is " + first_name);
    console.log(contact);
    //return;
    const contact_data = contact.toDataURI();
    navigate(`/view?contact=${contact_data}`)
  }

  async function saveContact(e) {
    const url = !appState.isLoggedIn ? "/api/sign_up_with_contact" : "/api/contact"
    let field_keys = Contact.CONTACT_ATTRS.map(v=>v.key);
    field_keys.push("is_reference");
    const is_reference = contact.is_reference == true;

    const res = await contact.save({
      url: url,
      withFields: field_keys,
    });
    if (res.success) {
      if (mode == 'create') {
        if (!appState.isLoggedIn) {
          await appState.loadGlobalContext();
        }
        navigate( (is_reference ? contact.view_path : contact.qr_path) );
      } else {
        toast({
          status: "success",
          title: "Contact profile saved."
        })
      }
    } else {
      toast({
        status: "error",
        title: "Contact profile could not be saved.",
        description: res.error_message
      })
    }
  }

  function showSocial(key) {
    setSocialState({...socialState, [key]: true})
  }

  function isSocialKeyShown(key) {
    return socialState[key] == true || contact[key];
  }

  function shownSocials() {
    return socials.filter((s)=>isSocialKeyShown(s.key));
  }

  function hiddenSocials() {
    return socials.filter((s)=>!isSocialKeyShown(s.key));
  }

  function toggleIsReference() {
    if (contact.is_reference) {
      contact.set('is_reference', false);
    } else {
      contact.set('is_reference', true);
    }
  }

  return (
    <Card minW="full">
      <VStack align='stretch' spacing='8'>
        <VStack align='stretch'>
          <HStack spacing='4'>
            <Icon as={MdAccountCircle} w={8} h={8} color="green" verticalAlign='middle' />
            <Heading size='md'>{ title }</Heading>
          </HStack>
          {!contact.is_reference && mode == 'create' && appState.isLoggedIn && (
            <Alert variant="subtle" status="success" borderRadius="md">
              <AlertIcon/>
              <AlertDescription>
                <AlertTitle>
                  Want to save someone else's business card?
                  <Button variant="link" colorScheme="black" fontWeight="normal" textDecoration="underline" ml={2} onClick={()=>{navigate("/create?reference=true")}}>Get Started</Button>
                </AlertTitle>
              </AlertDescription>
            </Alert> )
          }
          {contact.is_reference && (
            <Alert variant="left-accent" borderRadius="md">
              <AlertDescription>
                <AlertTitle>You are entering a QR card on behalf of someone else.</AlertTitle>
                Enter their info here and we'll add them to your <Link as={RouterLink} to="/search?index=bookmarked" textDecoration="underline">bookmarks</Link>. They can even claim the card later to keep it updated!
              </AlertDescription>
            </Alert>
          )}
        </VStack>
        <VStack align='stretch' spacing={4}>
          <Heading size='sm'>Primary Info</Heading>
          <HStack spacing='4' alignItems="top">
            <ModelFormField type='text' label="First name" model={contact} field="first_name" variant="floating"/>
            <ModelFormField type='text' label="Last name" model={contact} field="last_name" variant="floating"/>
          </HStack>
          <HStack spacing='4' alignItems="top">
            <ModelFormField type='text' label="Company" model={contact} field="company" variant="floating"/>
            <ModelFormField type='text' label="Job Title" model={contact} field="company_title" variant="floating"/>
          </HStack>
        </VStack>

        <VStack align='stretch' spacing={4}>
          <Heading size='sm'>Phone + Email</Heading>
          <ModelFormField type='text' label="Mobile phone" model={contact} field="mobile_phone" leftIcon={IoPhonePortrait} variant="floating"/>
          <HStack spacing='4' alignItems="top">
            <ModelFormField type='text' label="Home phone" model={contact} field="home_phone" leftIcon={IoHome} variant="floating"/>
            <ModelFormField type='text' label="Work phone" model={contact} field="work_phone" leftIcon={IoBusiness} variant="floating"/>
          </HStack>
          <ModelFormField type='email' label="Email" model={contact} field="email" leftIcon={IoMail} variant="floating"/>
        </VStack>

        <VStack align='stretch' spacing={4}>
          <Heading size='sm'>Address</Heading>
          <ModelFormField type='text' label="Street" model={contact} field="street_1" variant="floating" />
          <ModelFormField type='text' label="Apt / Suite / Other" model={contact} field="street_2" variant="floating" />
          <ModelFormField type='text' label="City" model={contact} field="city" variant="floating" />
          <HStack spacing='4' alignItems="top">
            <ModelFormField type='text' label="State" model={contact} field="state" variant="floating" />
            <ModelFormField type='text' label="Zip" model={contact} field="postal_code" variant="floating" />
          </HStack>
        </VStack>


        <VStack align='stretch' spacing={4}>
          <Heading size='sm'>Web + Social</Heading>
          <SimpleGrid columns={{sm: 1, md: 2}} spacingX={4} spacingY={4}>
            <ModelFormField type='text' label="Website" model={contact} field="website" leftIcon={IoGlobe} variant="floating" />
            {shownSocials().map((social)=> 
              <ModelFormField key={social.key} type='text' label={social.name} model={contact} field={social.key} leftIcon={social.icon} variant="floating"/>
            )}
          </SimpleGrid>
          <HStack alignItems="top">
            {hiddenSocials().map((social)=>
              <Tooltip label={social.name} hasArrow key={social.key}>
                <Button onClick={()=>showSocial(social.key)}>
                  <Icon as={social.icon} />
                </Button>
              </Tooltip>
            )}
          </HStack>
        </VStack>

        {mode=='edit' && (
        <VStack align='stretch'>
          <Heading size='sm'>Bio</Heading>
          <ModelFormField type="textarea" placeholder="Tell us about yourself..." model={contact} field="bio" />
        </VStack>)}

        <Button colorScheme='green' leftIcon={mode == 'create' ? <IoQrCodeOutline/> : null} onClick={saveContact} isDisabled={!contact.is_valid} isLoading={contact.is_saving}>{ buttonTitle }</Button>
      </VStack>
    </Card>

  )
});

export default ContactEditForm;
